<template>
  <div class="moderator-all">
    <div class="filter-wrap">
      <div class="top-search dfr flex-center">
        <van-search
          v-model="keyword"
          placeholder="请输入主持人姓名"
          @blur="searchBlur"
          :left-icon="leftIcon"
        />
      </div>

      <!-- filter -->
      <van-dropdown-menu active-color="#CB9025">
        <van-dropdown-item
          :title-class="dateValue != '' ? 'active' : 'unactive'"
          title="日期"
          ref="date"
        >
          <div class="date-inner dfc flex-center">
            <van-datetime-picker
              class="w100"
              :show-toolbar="false"
              v-model="dateValue"
              type="date"
              :visible-item-count="3"
              :min-date="minDate"
              :max-date="maxDate"
              @change="dateChange"
              @confirm="dateConfirm"
              :formatter="formatter"
            >
            </van-datetime-picker>
            <icy-button @click.native="selectDate" class="date-confirm"
              >确定</icy-button
            >
          </div>
        </van-dropdown-item>
        <van-dropdown-item
          :title-class="currentTime != '' ? 'active' : 'unactive'"
          title="时段"
          v-model="currentTime"
          :options="timeOption"
          @change="timeChange"
        />
        <van-dropdown-item
          :title-class="currentField != '' ? 'active' : 'unactive'"
          title="领域"
          v-model="currentField"
          :options="fieldList"
          @change="fieldChange"
        />
        <van-dropdown-item
          :title-class="currentLevel != '' ? 'active' : 'unactive'"
          title="等级"
          v-model="currentLevel"
          :options="levelList"
          @change="levelChange"
        />
      </van-dropdown-menu>
    </div>

    <!-- 主持人 -->
    <!-- <div v-if="moderatorList.length" class="moderator-wrap"> -->
    <!-- 筛选的结果 -->
    <empty
      content="暂无合适的主持人"
      v-if="!moderatorList.length && !recommendList.length"
    ></empty>
    <div v-else>
      <div
        v-for="item in moderatorList"
        :key="`${item.id}list`"
        class="moderator-card"
      >
        <moderator-select-card
          :currentTime="currentTime"
          :currentSelectDate="currentSelectDate"
          :info="item"
          :isReplace="isReplace"
        ></moderator-select-card>
      </div>
      <div
        v-if="recommendList.length"
        class="recomment-line fs24 fcccc text-center"
      >
        没有更多匹配的，推荐一些
      </div>
      <!-- 推荐 -->
      <div
        v-for="item in recommendList"
        :key="`${item.id}recommend`"
        class="moderator-card recommend"
      >
        <moderator-select-card
          :currentTime="currentTime"
          :currentSelectDate="currentSelectDate"
          isRecommend
          :info="item"
          :isReplace="isReplace"
        ></moderator-select-card>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      keyword: "",
      dateValue: "",
      beDate: "",
      minDate: "",
      maxDate: "",
      currentTime: "",
      currentSelectDate: "",
      timeOption: [
        { text: "不限", value: "" },
        { text: "09:00-12:00", value: "0" },
        { text: "14:00-17:00", value: "1" },
        { text: "19:00-21:00", value: "2" }
      ],
      currentLevel: "",
      levelList: [],
      currentField: "",
      fieldList: [],
      moderatorList: [],
      recommendList: [],
      page: 1,
      pageSize: "",
      recommendPage: 1,
      recommendPageSize: "",
      leftIcon: require("../assets/images/search.png"),
      isReplace: 0
    };
  },
  created() {
    this.isReplace = parseInt(this.$route.query.isReplace) || 0;
    this.minDate = new Date(dayjs().format("YYYY/MM/DD"));
    this.maxDate = new Date("2100/01/01");
    let selectDate = this.$route.query.date || "";
    let timeType = parseInt(this.$route.query.timeType);

    // let timeTypes = ["09:00-12:00", "14:00-17:00", "19:00-21:00"];

    if (timeType || timeType === 0) {
      this.currentTime = timeType + "";
    }

    if (selectDate) {
      let currentDate = selectDate.replace(/年|月/g, "-");
      currentDate = currentDate.slice(0, currentDate.length - 1);
      let data = new Date(dayjs(currentDate).format("YYYY/MM/DD"));
      this.dateValue = data;
      this.currentSelectDate = currentDate;
    }
    this.initPage();
  },
  mounted() {},
  methods: {
    searchBlur() {
      this.initModerator();
      this.initRecommend();
    },
    initPage() {
      this.initLevel();
      this.initField();
      this.initModerator();
      this.initRecommend();
    },
    //   主持人列表
    async initModerator() {
      let _date = "";
      if (this.dateValue) {
        _date = dayjs(this.dateValue).format("YYYY-MM-DD");
      }
      const data = {
        page: this.page,
        pageSize: this.pageSize,
        keyword: this.keyword,
        levelId: this.currentLevel,
        fieldId: this.currentField,
        timeType: this.currentTime,
        date: _date,
        hasDate: 1
      };
      const resp = await this.$API
        .get("moderatorList", data, false)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.moderatorList = resp.data.list;
      }
    },
    async initRecommend() {
      let _date = "";
      if (this.dateValue) {
        _date = dayjs(this.dateValue).format("YYYY-MM-DD");
      }
      const data = {
        page: 1,
        pageSize: "",
        keyword: this.keyword,
        levelId: this.currentLevel,
        fieldId: this.currentField,
        timeType: this.currentTime,
        date: _date
      };
      const resp = await this.$API
        .get("moderatorRecommend", data, false)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.recommendList = resp.data.list;
      }
    },
    selectDate() {
      this.$refs.date.toggle();
    },
    levelChange() {
      this.initModerator();
      this.initRecommend();
    },
    fieldChange() {
      this.initModerator();
      this.initRecommend();
    },
    timeChange() {
      this.initModerator();
      this.initRecommend();
    },
    async initLevel() {
      const resp = await this.$API
        .get("levelList", "", false)
        .then(resp => resp);
      if (resp.code == 1000) {
        let list = resp.data;
        list.map(item => {
          item.text = item.name;
          item.value = item.id;
          return item;
        });
        list.unshift({ text: "不限", value: "" });

        this.levelList = list;
      }
    },
    async initField() {
      const resp = await this.$API
        .get("fieldList", "", false)
        .then(resp => resp);
      if (resp.code == 1000) {
        let list = resp.data;
        list.map(item => {
          item.text = item.name;
          item.value = item.id;
          return item;
        });
        list.unshift({ text: "不限", value: "" });
        this.fieldList = list;
      }
    },
    dateChange(data) {
      this.dateValue = data.getValues().join("");
      let beDate = this.dateValue.replace(/年|月/g, "-");
      beDate = beDate.slice(0, beDate.length - 1);
      this.dateValue = beDate;
      this.currentSelectDate = beDate;
      this.initModerator();
      this.initRecommend();
    },
    dateConfirm(data) {
      let YYYY = dayjs(data).format("YYYY");
      let MM = dayjs(data).format("MM");
      let DD = dayjs(data).format("DD");
      this.dateValue = `${YYYY}年${MM}月${DD}日`;
      this.selectDate = data;
      this.datePopupShow = false;
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      }
      return val;
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.moderator-all {
  padding-bottom: 0.28rem;
}
.recomment-line {
  margin: 0.2rem 0;
}
.recommend:first-child {
  margin-top: 0 !important;
}
.filter-wrap {
  position: sticky;
  top: 0;
  z-index: 10;
  height: 1.84rem;
  display: flex;
  flex-direction: column;

  .top-search {
    height: 0.96rem;
    width: 7.5rem;
    background: #ffffff;
    // border: 1px solid red;
  }
  .van-dropdown-menu {
    background: #fff;
  }
}
.date-inner {
  padding: 0.5rem 0 0.2rem;
  .date-confirm {
    margin-top: 0.6rem;
  }
}
.moderator-wrap {
  padding: 0 0 0.4rem;
}
</style>
